var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pb-5 justify-center", attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "px-10", attrs: { xs12: "", md12: "" } },
        [
          _vm.isSearching
            ? _c("v-progress-circular", {
                attrs: { size: 50, color: "primary", indeterminate: "" }
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c("staying-list-item", {
                attrs: { name: "staying", id: 0, selected: _vm.selected },
                on: {
                  toggle: _vm.toggleSelected,
                  updateSelection: _vm.updateStayLeaveSelection
                }
              }),
              _c("staying-list-item", {
                attrs: {
                  name: "leaving",
                  id: 1,
                  selected: _vm.selected,
                  options: _vm.stayChoiceInfoItems_leaving
                },
                on: {
                  toggle: _vm.toggleSelected,
                  updateSelection: _vm.updateStayLeaveSelection
                }
              }),
              _c("staying-list-item", {
                attrs: {
                  name: "undecided",
                  label: _vm.$t("general.stayinguser.undecided"),
                  id: 2,
                  selected: _vm.selected,
                  options: _vm.stayChoiceInfoItems_undecided
                },
                on: {
                  toggle: _vm.toggleSelected,
                  updateSelection: _vm.updateStayLeaveSelection
                }
              }),
              _vm.selected != 0
                ? _c(
                    "p",
                    { staticClass: "ma-3 mb-12 font-italic text-body-2" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("general.stayinguser.undecided-note"))
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "pb-5 justify-center", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "pa-4",
              attrs: { xs12: "", md4: "", "justify-center": "" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-uppercase white--text",
                  attrs: {
                    outlined: "",
                    rounded: "",
                    "x-large": "",
                    color: _vm.company,
                    block: "",
                    disabled: !_vm.disableSave
                  },
                  on: { click: _vm.updateStatus }
                },
                [_vm._v(_vm._s(_vm.btn_save) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }