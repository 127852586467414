var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name
    ? _c(
        "v-card",
        {
          staticClass: "ma-3 my-5 pointer payment-row",
          class: { "selected-row": _vm.isSelected },
          attrs: { flat: "" }
        },
        [
          _c(
            "v-list-item",
            {
              attrs: { "three-line": "" },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle", _vm.id)
                }
              }
            },
            [
              _vm.isSelected
                ? _c(
                    "div",
                    { staticClass: "check-mark-container" },
                    [
                      _c("v-img", {
                        staticClass: "check-mark",
                        attrs: {
                          alt: "checkMark",
                          src: require("@assets/icons/check-mark.svg"),
                          transition: "scale-transition",
                          width: "40px"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item-avatar",
                { staticClass: "mr-5", attrs: { tile: "", size: "80" } },
                [
                  _c("v-img", {
                    staticClass: "item-logo pa-5",
                    attrs: {
                      src: _vm.img,
                      contain: "",
                      transition: "scale-transition",
                      width: "25px"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "headline mb-1" }, [
                    _vm._v(_vm._s(_vm.getName))
                  ]),
                  _vm.selected === _vm.id && _vm.options
                    ? _c(
                        "v-list-item-subtitle",
                        [
                          _c("v-select", {
                            staticClass: "ma-3 my-5",
                            attrs: {
                              "item-text": "name",
                              "item-value": "_id",
                              items: _vm.options,
                              label: _vm.$t(
                                "general.stayinguser.dropdown-decission"
                              ),
                              outlined: "",
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateSelection($event)
                              }
                            },
                            model: {
                              value: _vm.selectedOption,
                              callback: function($$v) {
                                _vm.selectedOption = $$v
                              },
                              expression: "selectedOption"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selected === _vm.id &&
                  _vm.options &&
                  (_vm.selectedOption ==
                    "general.stayinguser.leaving.answer8" ||
                    _vm.selectedOption ==
                      "general.stayinguser.undecided.answer5" ||
                    _vm.selectedOption == "general.stayinguser.leaving.answer5")
                    ? _c(
                        "v-list-item-subtitle",
                        [
                          _c("v-textarea", {
                            staticClass: "ma-3 text-body-2",
                            attrs: {
                              outlined: "",
                              label: _vm.$t(
                                "general.stayinguser.other-choice-more-info"
                              ),
                              required: ""
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.emitValues()
                              }
                            },
                            model: {
                              value: _vm.otherMoreInfo,
                              callback: function($$v) {
                                _vm.otherMoreInfo = $$v
                              },
                              expression: "otherMoreInfo"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }